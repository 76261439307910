import React, { useState, useEffect } from 'react';
import VideoSection from '../VideoSection/VideoSection';
import FeatureRow from '../FeatureRow/FeatureRow';
import CategoryRow from '../CategoryRow/CategoryRow';
import CountryRow from '../CountryRow/CountryRow';
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet-async';
import Facts from '../Facts/Facts'

import './Home.css';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [videoData, setVideoData] = useState([]); // State for video data

  // Map of tag1 categories to description templates
  const descriptionTemplates = {
    // News
    News: (name, wikidata) =>
      `Stay updated with the latest from ${name}, a hotspot for breaking news in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    LiveEvents: (name, wikidata) =>
      `Catch the energy of live events happening at ${name}, located in ${wikidata}. Watch it unfold on <span class='earf-blue'>Earf</span>.`,
    Weather: (name, wikidata) =>
      `Experience the current weather conditions and breathtaking scenery at ${name}, located in ${wikidata}. Stay informed on <span class='earf-blue'>Earf</span>.`,
    Sports: (name, wikidata) =>
      `Dive into the excitement of live sports at ${name}, located in ${wikidata}. Explore it now on <span class='earf-blue'>Earf</span>.`,
    Festivals: (name, wikidata) =>
      `Celebrate the energy and culture of ${name}, a highlight of ${wikidata}'s festivals. Watch it live on <span class='earf-blue'>Earf</span>.`,

    // Tours
    WalkingTour: (name, wikidata) =>
      `Take a virtual walking tour through ${name}, a captivating location in ${wikidata}. Explore it now on <span class='earf-blue'>Earf</span>.`,
    DrivingTour: (name, wikidata) =>
      `Join a scenic driving tour of ${name}, offering stunning views of ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    DroneTour: (name, wikidata) =>
      `Soar above the breathtaking landscapes of ${name}, located in ${wikidata}, with this immersive drone tour. Watch it live on <span class='earf-blue'>Earf</span>.`,

    // Urban
    CapitalCity: (name, wikidata) =>
      `Discover the vibrant energy of ${name}, a remarkable capital city in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    City: (name, wikidata) =>
      `Explore the bustling streets and unique charm of ${name}, located in ${wikidata}. Experience it live on <span class='earf-blue'>Earf</span>.`,
    Village: (name, wikidata) =>
      `Step into the tranquility of ${name}, a charming village nestled in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Town: (name, wikidata) =>
      `Uncover the unique character of ${name}, a delightful town in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Urban: (name, wikidata) =>
      `Immerse yourself in the rhythm of ${name}, a vibrant urban location in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Skylines: (name, wikidata) =>
      `Marvel at the breathtaking skyline of ${name}, located in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Nightlife: (name, wikidata) =>
      `Experience the vibrant nightlife of ${name}, a hotspot in ${wikidata}. Watch it come alive on <span class='earf-blue'>Earf</span>.`,
    Harbours: (name, wikidata) =>
      `Explore the scenic beauty of ${name}, a bustling harbour in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    TrainStations: (name, wikidata) =>
      `Discover the dynamic atmosphere of ${name}, a busy train station in ${wikidata}. Watch the action live on <span class='earf-blue'>Earf</span>.`,
    Railways: (name, wikidata) =>
      `Follow the journey along the scenic railways of ${name}, nestled in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Island: (name, wikidata) =>
      `Escape to the idyllic ${name}, a stunning island paradise in ${wikidata}. Watch its beauty live on <span class='earf-blue'>Earf</span>.`,
    Airports: (name, wikidata) =>
      `Explore the excitement and movement of ${name}, a major airport in ${wikidata}. Watch the activity live on <span class='earf-blue'>Earf</span>.`,

    // Nature
    Wildlife: (name, wikidata) =>
      `Witness the incredible wildlife at ${name}, a natural wonder in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Beaches: (name, wikidata) =>
      `Relax with stunning views of ${name}, one of the most beautiful beaches in ${wikidata}. Explore more on <span class='earf-blue'>Earf</span>.`,
    Mountains: (name, wikidata) =>
      `Explore the majestic beauty of ${name}, a breathtaking mountain in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Countryside: (name, wikidata) =>
      `Discover the peaceful charm of ${name}, a serene countryside retreat in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Lakes: (name, wikidata) =>
      `Experience the tranquility of ${name}, a stunning lake in ${wikidata}. Watch its beauty live on <span class='earf-blue'>Earf</span>.`,
    OceanViews: (name, wikidata) =>
      `Immerse yourself in the vastness of ${name}, a breathtaking ocean view in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Parks: (name, wikidata) =>
      `Take a stroll through ${name}, a beautiful park in ${wikidata}. Experience its natural charm live on <span class='earf-blue'>Earf</span>.`,
    Rainforests: (name, wikidata) =>
      `Journey into the lush greenery of ${name}, a vibrant rainforest in ${wikidata}. Watch its wonders live on <span class='earf-blue'>Earf</span>.`,
    Rivers: (name, wikidata) =>
      `Follow the course of ${name}, a stunning river in ${wikidata}. Watch its flow live on <span class='earf-blue'>Earf</span>.`,
    Waterfalls: (name, wikidata) =>
      `Marvel at the cascading beauty of ${name}, a spectacular waterfall in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Deserts: (name, wikidata) =>
      `Explore the mysterious beauty of ${name}, a vast desert in ${wikidata}. Watch its wonders live on <span class='earf-blue'>Earf</span>.`,
    CoralReefs: (name, wikidata) =>
      `Dive into the vibrant underwater world of ${name}, a stunning coral reef in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Farms: (name, wikidata) =>
      `Experience the simplicity of ${name}, a charming farm in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Volcanoes: (name, wikidata) =>
      `Witness the power of ${name}, a majestic volcano in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,

    // Other
    Landmarks: (name, wikidata) =>
      `Explore ${name}, an iconic landmark that embodies history, culture, and ingenuity in ${wikidata}. Experience it live on <span class='earf-blue'>Earf</span>.`,
    ThemeParks: (name, wikidata) =>
      `Dive into the excitement at ${name}, a thrilling theme park destination in ${wikidata}. Join the fun on <span class='earf-blue'>Earf</span>.`,
    CulturalSites: (name, wikidata) =>
      `Discover the rich cultural heritage of ${name}, a fascinating site located in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    HistoricSites: (name, wikidata) =>
      `Step back in time at ${name}, a historic site that brings the past to life in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    HiddenGems: (name, wikidata) =>
      `Uncover ${name}, a hidden gem waiting to be explored in ${wikidata}. Discover its charm live on <span class='earf-blue'>Earf</span>.`,
    LocalFavorites: (name, wikidata) =>
      `Experience the vibrant local charm of ${name}, a beloved favorite in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    IconicViews: (name, wikidata) =>
      `Take in the breathtaking views from ${name}, a truly iconic spot in ${wikidata}. Experience it live on <span class='earf-blue'>Earf</span>.`,
    FamilyFriendly: (name, wikidata) =>
      `Enjoy the family-friendly activities at ${name}, a destination for all ages in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Zoos: (name, wikidata) =>
      `Discover the amazing wildlife at ${name}, a world-class zoo in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Aquariums: (name, wikidata) =>
      `Dive into the wonders of marine life at ${name}, an exceptional aquarium located in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    BotanicalGardens: (name, wikidata) =>
      `Wander through the lush beauty of ${name}, a stunning botanical garden in ${wikidata}. Explore its wonders live on <span class='earf-blue'>Earf</span>.`,
    AnimalSanctuaries: (name, wikidata) =>
      `Visit ${name}, a sanctuary dedicated to protecting and caring for animals in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Ports: (name, wikidata) =>
      `Explore the dynamic activity of ${name}, a bustling port connecting ${wikidata} to the world. Watch it live on <span class='earf-blue'>Earf</span>.`,

    // Default
    Default: (name, wikidata) =>
      `Explore ${name}, located in ${wikidata}. Discover its charm live on <span class='earf-blue'>Earf</span>.`,
  };

  // Function to generate a description dynamically based on tag1
  const generateDescription = (tag1, name, wikidata) => {
    const template = descriptionTemplates[tag1] || descriptionTemplates.Default;
    return template(name, wikidata);
  };

  
  const fetchVideos = async () => {
    try {
      const liveResponse = await fetch('https://server.earf.tv/live-cameras', {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,
          'Content-Type': 'application/json',
          'X-Request-Source': 'Video-Section-Live',
        },
      });

      if (!liveResponse.ok) {
        throw new Error('Failed to fetch video data.');
      }

      const { features } = await liveResponse.json();

// Filter to include only features with status 'online'
const onlineFeatures = features.filter(
  (feature) =>
    feature.properties &&
    feature.properties.status &&
    feature.properties.status.toLowerCase() === 'online'
);



      // Ensure unique videos by `wikidata` (if available) or `videoId`
      const uniqueVideos = [];
      const usedKeys = new Set();

      onlineFeatures.forEach((feature) => {
        const { properties } = feature;

        // Use `wikidata` if available; fall back to `videoId` for uniqueness
        const uniqueKey = properties.wikidata || properties.videoId;

        if (!usedKeys.has(uniqueKey)) {
          uniqueVideos.push({
            id: properties.videoId,
            title: properties.name,
            youtubeId: properties.videoId, // Assuming videoId is YouTube's video ID
            thumbnail: `https://img.youtube.com/vi/${properties.videoId}/hqdefault.jpg`,
            wikidata: properties.wikidata,
            tags: [properties.tag1, properties.tag2, properties.tag3].filter(Boolean),
            description: generateDescription(properties.tag1, properties.name, properties.wikidata),
          });
          usedKeys.add(uniqueKey); // Mark this `wikidata` or `videoId` as used
        }
      });

      // Shuffle the final unique videos and limit to 10
      const shuffledVideos = uniqueVideos.sort(() => Math.random() - 0.5).slice(0, 10);

      setVideoData(shuffledVideos); // Set the video data for VideoSection
    } catch (error) {
      console.error('Error fetching videos:', error.message);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };



  useEffect(() => {
    fetchVideos();
  }, []);


  return (
    <div className="home-page-container">
      <Helmet prioritizeSeoTags>
        <title>Earf</title>
        <meta name="author" content="Earf Team" />
        <meta name="keywords" content="live earth cameras, global radio, weather updates, Earf, live streaming, global broadcasts, Earth live feed, Earf.tv, Earf, Interactive.Earth, Interactive, Earth" />
        <link rel="canonical" href="https://earf.tv" />
        <meta property="og:title" content="Earf" />
        <meta property="og:description" content="Earf is an interactive live-streaming platform showcasing Earth's beauty through live cameras, global radio, real-time weather, and more. There’s something for everyone to explore on Earf." />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Earf" />
        <meta name="twitter:description" content="Earf is an interactive live-streaming platform showcasing Earth's beauty through live cameras, global radio, real-time weather, and more." />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Logo" />
        <meta name="description" content="Earf is an interactive live-streaming platform showcasing Earth's beauty through live cameras, global radio, real-time weather, and more. There’s something for everyone to explore on Earf." />
      </Helmet>

      {isLoading ? (
        <div className="full-container">
          <div className="spinner-wrapper">
            <div className="spinner"></div>
          </div>
          <Facts />
        </div>
      ) : (
        <>
          <VideoSection videoData={videoData} />
          <FeatureRow />
          <div className="spacer-line1"></div>

          <CategoryRow />
          <div className="spacer-line1"></div>

          <CountryRow />
          
          <div className="spacer-line1"></div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Home;
