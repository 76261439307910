import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Correct import from react-router-dom
import './Changelog.css';
import { Helmet } from 'react-helmet-async';

const Changelog = () => {
  // An array of changelog entries, each with a title, date, and details.
  const changelogs = [
    {
      title: 'Version 1.1.0',
      date: 'Underway',
      changes: [
        "Added new logo to fit inline with 2025 branding.",
        "Implemented the Geolocate feature on the Explore (Map) page, allowing users to find their current location and explore nearby features conveniently.",
        "Implemented Cartel/Gang Territories Overlay on the Explore (Map) page, displaying cartel-controlled areas in Mexico based on real-world data. (Mexico only at this stage)",
        "Cartel overlay dynamically adjusts visibility based on zoom level (only visible at zoom 5 or higher).",
        "Users can now gain more information and learn about cartels or gangs by clicking on the Cartel/Gang Name in the overlay. This action will open the corresponding Wikipedia page for the selected cartel or gang in a new tab, allowing for easy access to more detailed information.",
        "Refined cartel detection logic to exclude 'Unknown' areas and only display known cartel-controlled or disputed territories.",
        "Stream View now fetches streams directly by video ID rather than searching through all features.",
        "Added fallback logic to automatically display the next live stream for that location from the partner's channel if the current stream is detected offline.",
        "Enhanced the Browse functionality to provide a seamless experience when viewing live and categorized streams.",
        "Improved search results for tags to ensure comprehensive and accurate data.",
        "Streamlined backend processes to deliver faster and more reliable data for streams and categories.",
        "Revamped RainViewer integration for optimized performance and a more lightweight experience.",
        "Removed Toastify popups from RainViewer, reducing UI distractions and improving performance.",
        "Weather radar animations now transition smoother and update every 5 minutes in the background.",
        "Fixed minor bug where traffic layer was not updating correctly.",
      ],
    },
    {
      title: 'Version 1.0.0',
      date: 'December 7, 2024',
      changes: [
        'Added 350+ new live cameras from various locations around the world.',
        'Implemented a custom API to gather real-time latitude and longitude data for the International Space Station (ISS).',
        'Integrated ISS tracking on the frontend, with support for its live camera in the Stream View.',
        'Optimized API interactions for faster data retrieval and reduced latency in fetching country profiles and live camera data.',
        'Fixed inaccuracies in the "Popular Countries" feature, ensuring correct ranking of countries with the most live cameras.',
        'Enhanced backend monitoring for stream availability, adding auto-switching to alternative feeds when primary streams go offline.',
        'Launched custom APIs for weather, local time, air quality, and historical facts, integrated across the platform.',
        'Added "Today in History" facts on all load screens using a custom API.',
        'Improved platform-wide performance, reducing load times and enhancing responsiveness.',
        'Redesigned the home page with updated layouts, resized icons, and dynamic scaling for responsive screens.',
        'Refined core UI styles and layouts for consistency and adaptability across devices.',
        'Updated "Popular Countries" feature with flags and direct links to country profiles.',
        'Refined country profiles with updated flags, descriptions, and geographical details.',
        'Moved 95% of API calls and key management to the backend for improved security and key handling.',
        'Standardized location-based data handling with backend-driven weather, time, and air quality features.',
      ],
    }, ,
    {
      title: 'Version 0.1.0',
      date: 'October 9, 2024',
      changes: [
        'The Big Bang - Earf is Created!',
        'Interactive.Earth has transformed into something greater.',
        'Hello World :)',
      ],
    },

  ];




  // State to track which changelog entry is expanded
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate(); // Corrected import for useNavigate

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigates to the previous page
  };

  return (

    <div className="changelog-container">

      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Changelog | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Stay updated with the latest features, improvements, and updates on Earf. See what's new and how we're evolving the platform to enhance your experience."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/changelog" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Changelog | Earf" />
        <meta
          property="og:description"
          content="Discover the latest changes, new features, and improvements on Earf. Stay informed on how we're continuously enhancing the platform for a better user experience."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/changelog" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Changelog | Earf" />
        <meta
          name="twitter:description"
          content="Get the latest updates on Earf. Explore new features, improvements, and enhancements designed to improve your live streaming experience."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Changelog" />
      </Helmet>


      {/* Header Section with Back Button and Title */}
      <div className="changelog-header-container">
        <h1 className="changelog-title">Changelog</h1>
        <button onClick={handleBackClick} className="back-button">
          &larr; Back
        </button>
      </div>

      {changelogs.map((log, index) => (
        <div key={index} className="changelog-entry">
          <div className="changelog-header" onClick={() => toggleExpand(index)}>
            <h3>{log.title}</h3>
            <p>{log.date}</p>
            <button className="expand-btn">
              {expandedIndex === index ? 'Collapse' : 'Expand'}
            </button>
          </div>
          {expandedIndex === index && (
            <div className="changelog-details">
              <ul>
                {log.changes.map((change, idx) => (
                  <li key={idx}>{change}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Changelog;
